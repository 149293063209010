
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'

export default defineComponent({
  setup() {
    return {}
  },
  components: {
    Header
  }
})
